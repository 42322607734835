<template>
  <v-container class="pa-5 mb-13">
    <v-card min-height="80vh">
      <v-card-text>
        <!-- Search panel -->
        <v-row>
          <v-col cols="8">
            <v-text-field
              :label="
                searchBy == 'Invoice Number' ? 'Invoice Number' : 'Phone Number'
              "
              outlined
              dense
              append-icon="mdi-magnify"
              type="number"
              v-model="search"
              v-on:keyup.enter="searchInvoice"
              :rules="rules"
            >
              <template v-slot:prepend>
                <v-select
                  :items="items"
                  label="Search by"
                  outlined
                  dense
                  style="width: 200px"
                  v-model="searchBy"
                ></v-select>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <!-- If Invoice exists -->
        <v-row v-if="searched">
          <v-col cols="12">
            <div v-if="loading" class="d-flex justify-center mt-16">
              <v-progress-circular
                indeterminate
                :size="80"
                :width="8"
                color="red"
              >
              </v-progress-circular>
            </div>
            <v-data-table
              v-else
              :headers="headers"
              :items="invoices"
              :items-per-page="15"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="viewInvoice(item)">
                  mdi-eye
                </v-icon>
              </template>

              <template v-slot:item.motherinvoice="{ item }">
                <v-btn @click="viewMotherInvoice(item)" icon>
                  <v-icon dark class="pr-5" style="margin-left: 0 !important">
                    mdi-eye-outline</v-icon
                  ></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    items: ["Invoice Number", "Phone Number"],
    searchBy: "Invoice Number",
    search: "",
    searched: false,
    loading: false,

    headers: [
    //   {
    //     text: "Order ID",
    //     align: "start",
    //     value: "id",
    //     sortable: false,
    //   },
      { text: "Invoice ID", value: "invoice_id", sortable: false },
      { text: "Phone No.", value: "phone_number", sortable: false },
      { text: "Date", value: "ordered_date", sortable: false },
      { text: "Price ", value: "price_total", sortable: false },
      { text: "Order Status", value: "order_status", sortable: false },
      { text: "Admin Status", value: "admin_status", sortable: false },
      { text: "Delivery Status", value: "delivery_status", sortable: false },
      { text: "Invoice", value: "actions", sortable: false },
      { text: "Mother Invoice", value: "motherinvoice", sortable: false },
    ],
    invoices: [],
  }),

  computed: {
    rules() {
      const rules = [];

      if (this.searchBy == "Phone Number") {
        const rule = (v) =>
          /(^(\+88|0088)?(01){1}[56789]{1}(\d){8})$/.test(v) ||
          "Please provide valid phone number";

        rules.push(rule);
      }

      if (this.searchBy != "Phone Number") {
        const rule = (v) =>
          (v || "").length > 1 || "Please provide valid invoice number";

        rules.push(rule);
      }

      return rules;
    },
  },

  methods: {
    initialize() {},

    //Search Invoice
    searchInvoice() {
      // console.log(this.searchBy, this.search);
      let searchTerm = "";
      // Make API Call
      this.searched = true;
      this.loading = true;
      axios
        .post(`Cart/individual_orders/`, {
          searchBy: this.searchBy,
          search: this.search,
        })
        .then((response) => {
          // console.log("SEARCH RESULT ", response.data);
          if (response.data.success) {
            this.invoices = response.data.data;
          }else{
            this.invoices = []
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    viewInvoice(item) {
      if (item.reference_id == 0) {
        // this.$router.push({
        //   path: "/dashboard/pages/orderinvoice",
        //   query: { id: item.invoice_id },
        // });

        let routeData = this.$router.resolve({
          name: `/dashboard/pages/orderinvoice/?id=${item.invoice_id}`,
        });
        routeData.href = `/dashboard/pages/orderinvoice/?id=${item.invoice_id}`;
        window.open(routeData.href, "_blank");
      } else {
        // this.$router.push({
        //   path: "/dashboard/pages/new_invoice",
        //   query: { id: item.invoice_id },
        // });

        let routeData = this.$router.resolve({
          name: `/dashboard/pages/new_invoice/?id=${item.invoice_id}`,
        });
        routeData.href = `/dashboard/pages/new_invoice/?id=${item.invoice_id}`;
        window.open(routeData.href, "_blank");
      }
    },

    viewMotherInvoice(item) {
      if (item.reference_id == 0) {
        // this.$router.push({
        //   path: "/dashboard/pages/orderinvoice",
        //   query: { id: item.invoice_id },
        // });

        let routeData = this.$router.resolve({
          name: `/dashboard/pages/orderinvoice/?id=${item.invoice_id}`,
        });
        routeData.href = `/dashboard/pages/orderinvoice/?id=${item.invoice_id}`;
        window.open(routeData.href, "_blank");
      } else {
        // console.log("clicked");
        // this.$router.push({
        //   path: "/dashboard/pages/motherinvoice",
        //   query: { id: item.reference_id },
        // });

        let routeData = this.$router.resolve({
          name: `/dashboard/pages/motherinvoice/?id=${item.reference_id}`,
        });
        routeData.href = `/dashboard/pages/motherinvoice/?id=${item.reference_id}`;
        window.open(routeData.href, "_blank");
      }
    },
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}

.v-input__prepend-outer {
  margin-top: 0px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>